import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (<>
    <Helmet>
       <title>404: This page could not be found</title>            
   </Helmet>

    <Container fluid className="d-flex justify-content-center align-items-center bg-light">
      <Row className="text-center">
        <Col>
          <div className="my-5 py-5">
            <h1 className="display-1 fw-bold">404</h1>
            <h2 className="fw-light mb-4">Oops! Page Not Found</h2>
            <p className="lead mb-4">
              The page you are looking for does not exist.
            </p>
            <Link to="/">
              <Button size="lg" className='px-5' style={{backgroundColor:"#ffa22b", borderColor:"#ffa22b", color:"#000"}}>Go to Home</Button>
            </Link>
          </div> 
        </Col>
      </Row>
    </Container> 
    </>);
};

export default NotFound;
