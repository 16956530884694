import axios from 'axios';

//const API_URL = "http://localhost:8080/ors/api/blog";
const API_URL = "https://api.onlineroadservices.com/api/blog";

const getAllBlogs = async (page = 0, size = 10) => {
    try {
        const response = await axios.get(API_URL, {
            params: { page, size }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blogs');
    }
};

const getBlogById = async (id) => {
    try {
        const response = await axios.get(API_URL + `/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blog');
    }
};

const getRandomBlogs = async (size = 6) => {
    try {
        const response = await axios.get(`${API_URL}/random/${size}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blogs');
    }
};


const BlogService = {
    getAllBlogs,
    getBlogById,
    getRandomBlogs
};

export default BlogService;